// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html,
body {
  min-height: 100%;
  background-color: #f5f5f5;
  margin: 0;
}
.no-margin-top {
  margin-top: 0 !important;
}
.container {
  width: 100%;
  max-width: 1325px;
  margin: auto;
}
.header-button {
  color: lightgray;
}
.header-button:hover {
  color: white !important;
}
.ant-layout-sider-trigger {
  border-right: 1px solid #f0f0f0;
}
.select-project-title {
  transition: all 0.2s;
}
.select-project-title:hover {
  color: white !important;
  cursor: pointer;
}
.select-dataset-item {
  transition: all 0.2s;
  border-radius: 7px;
  cursor: pointer;
}
.select-dataset-item .ant-avatar {
  background-color: #8c8c8c;
}
.select-dataset-item:hover {
  background-color: #e6f4ff;
}
.select-dataset-item:hover .ant-avatar {
  color: #e6f4ff;
}
.select-dataset-item.selected,
.select-dataset-item.selected:hover {
  background-color: #91caff;
}
.select-dataset-item.selected .ant-avatar {
  background-color: #0958d9;
  color: #91caff;
}
`, "",{"version":3,"sources":["webpack://./src/styles.css"],"names":[],"mappings":"AAAA;;EAEE,gBAAA;EACA,yBAAA;EACA,SAAA;AACF;AAEA;EACE,wBAAA;AAAF;AAGA;EACE,WAAA;EACA,iBAAA;EACA,YAAA;AADF;AAIA;EACE,gBAAA;AAFF;AAKA;EACE,uBAAA;AAHF;AAMA;EACE,+BAAA;AAJF;AAOA;EACE,oBAAA;AALF;AAQA;EACE,uBAAA;EACA,eAAA;AANF;AASA;EACE,oBAAA;EACA,kBAAA;EACA,eAAA;AAPF;AASA;EACE,yBAAA;AAPF;AAUA;EACE,yBAAA;AARF;AAUA;EACE,cAAA;AARF;AAWA;;EACE,yBAAA;AARF;AAUA;EACE,yBAAA;EACA,cAAA;AARF","sourcesContent":["html,\nbody {\n  min-height: 100%;\n  background-color: #f5f5f5;\n  margin: 0;\n}\n\n.no-margin-top {\n  margin-top: 0 !important;\n}\n\n.container {\n  width: 100%;\n  max-width: 1325px;\n  margin: auto;\n}\n\n.header-button {\n  color: lightgray;\n}\n\n.header-button:hover {\n  color: white !important;\n}\n\n.ant-layout-sider-trigger {\n  border-right: 1px solid #f0f0f0;\n}\n\n.select-project-title {\n  transition: all 0.2s;\n}\n\n.select-project-title:hover {\n  color: white !important;\n  cursor: pointer;\n}\n\n.select-dataset-item {\n  transition: all 0.2s;\n  border-radius: 7px;\n  cursor: pointer;\n}\n.select-dataset-item .ant-avatar {\n  background-color: #8c8c8c;\n}\n\n.select-dataset-item:hover {\n  background-color: #e6f4ff;\n}\n.select-dataset-item:hover .ant-avatar {\n  color: #e6f4ff;\n}\n\n.select-dataset-item.selected, .select-dataset-item.selected:hover {\n  background-color: #91caff;\n}\n.select-dataset-item.selected .ant-avatar {\n  background-color: #0958d9;\n  color: #91caff;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
